import React from "react";
import { ContactWrapper, StyledDiv, Email } from "./ContactElements";

function Contact() {
  return (
    <ContactWrapper id="contact">
      <div className="Container">
        <div className="SectionTitle">kontakt</div>
        <div className="BigCard">
          <StyledDiv>Wiesław Kosowicz</StyledDiv>
          <StyledDiv>
            <a href="tel:+48 609 539 325">+48 609 539 325</a>
          </StyledDiv>
          <Email>w.kosowicz@draft-3d.com.pl</Email>
          <a
            className="btn PrimaryBtn"
            href="mailto:w.kosowicz@draft-3d.com.pl "
            target="_blank"
            rel="noopener noreferrer"
          >
            Wyślij Mail
          </a>
        </div>
      </div>
    </ContactWrapper>
  );
}

export default Contact;
