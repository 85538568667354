import React, { useState } from "react";
import Dropdown from "../Dropdown/Dropdown";
import Header from "../Header/Header";
import {
  Logo,
  HeroCompanyNameWrapper,
  HeroContainer,
  HeroWrapper,
  HeroLeft,
  HeroRight,
} from "./HeroElements";
function Hero() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <main id="header">
      <Dropdown isOpen={isOpen} toggle={toggle} />
      <Header toggle={toggle} />
      <HeroContainer>
        <HeroWrapper>
          <HeroLeft>
            <HeroCompanyNameWrapper>
              <Logo to="/">
                <img
                  src={require("../../assets/logo.png").default}
                  alt="logo"
                />
              </Logo>
              <h1>draft</h1>
            </HeroCompanyNameWrapper>

            <h5>biuro projektowe</h5>
            <p>
              Zajmujemy się świadczeniem usług z zakresu zaawansowanego
              projektowania, analiz wytrzymałościowych i obliczeń
              inżynieryjnych.
            </p>
          </HeroLeft>
          <HeroRight />
        </HeroWrapper>
      </HeroContainer>
    </main>
  );
}

export default Hero;
