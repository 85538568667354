import React, { useState } from "react";
import { ProjectList } from "../../../data/ProjectData";
import ProjectGallery from "../ProjectGallery/ProjectGallery";
import { Card, CardLeft, CardRight, BtnGroup } from "./ProjectCardElements";

function ProjectCard() {
  const [showGallery1, setShowGallery1] = useState(false);
  const [showGallery2, setShowGallery2] = useState(false);
  const [showGallery3, setShowGallery3] = useState(false);
  const [showGallery4, setShowGallery4] = useState(false);
  const [showGallery5, setShowGallery5] = useState(false);

  const setShowGallery = (index) => {
    switch (index) {
      case 1:
        setShowGallery1(!showGallery1);
        break;
      case 2:
        setShowGallery2(!showGallery2);
        break;
      case 3:
        setShowGallery3(!showGallery3);
        break;
      case 4:
        setShowGallery4(!showGallery4);
        break;
      case 5:
        setShowGallery5(!showGallery5);
        break;
      default:
    }
  };

  const showGallery = (index) => {
    switch (index) {
      case 1:
        return showGallery1;
      case 2:
        return showGallery2;
      case 3:
        return showGallery3;
      case 4:
        return showGallery4;
      case 5:
        return showGallery5;
      default:
    }
  };

  return (
    <>
      {ProjectList.map((list, index) => (
        <div key={index}>
          <Card className="BigCard" key={index}>
            <CardLeft>
              <img src={list.img} alt={list.name} />
            </CardLeft>
            <CardRight>
              <h4>{list.title}</h4>
              <p>{list.description}</p>
              <BtnGroup>
                <a
                  className="btn PrimaryBtn"
                  href={list.demo_url}
                  onClick={() => setShowGallery(index + 1)}
                >
                  {showGallery(index + 1) ? "Mniej ↑" : "Więcej ↓"}
                </a>
              </BtnGroup>
            </CardRight>
          </Card>

          {showGallery(index + 1) && <ProjectGallery photos={list.photos} />}
        </div>
      ))}
    </>
  );
}

export default ProjectCard;
