import React from "react";
import { Wrapper } from "./AboutElements";

function About() {
  return (
    <>
      <Wrapper id="about">
        <div className="Container">
          <div className="SectionTitle">o nas</div>
          <div className="BigCard">
            <div className="AboutBio">
              <p>
                Biuro Projektowe DRAFT powstało w 2007 roku. Od samego początku
                skupiamy się na realizacji projektów maszyn i urządzeń dla
                branży mechanicznej i lotniczej. Nasze bogate doświadczenie
                pozwala nam na tworzenie zaawansowanych projektów, które
                odpowiadają na bardzo wymagające potrzeby współczesnego rynku.
                Indywidualne podejście do każdego projektu gwarantuje wykonanie
                i wdrożenie urządzeń zgodnie z wymaganiami klienta.
              </p>
              <h3 className="margin-top">Oferta</h3>
              <ul>
                <li>
                  projektowanie kompletnych maszyn i urządzeń w celu wdrożenia
                  ich do produkcji
                </li>
                <li>tworzenie wirtualnych modeli 3D</li>
                <li>tworzenie rysunków złożeniowych oraz wykonawczych</li>
                <li>przygotowanie szczegółowej listy materiałowej</li>
                <li>
                  konwersję, odtworzenie i archiwizację dokumentacji z formy
                  papierowej do formy elektronicznej
                </li>
                <li>
                  tworzenie kompletnej dokumentacji technicznej do gotowych już
                  urządzeń lub wirtualnych modeli 3D urządzeń
                </li>
                <li>
                  inżynierię odwrotną – utworzenie dokumentacji i odtworzenie
                  starych oraz zużytych elementów maszyn
                </li>
                <li>
                  konwersję plików do różnych formatów 3D i 2D (m.in.: Step,
                  IGES, , DXF, DWG, PDF, itp.)
                </li>
                <li>
                  wsparcie techniczne podczas opracowywania prototypów maszyn
                  i urządzeń
                </li>
              </ul>
              <h3 className="margin-top">Oprogramowanie</h3>
              <ul>
                <li>
                  w pracach projektowych wykorzystujemy profesjonalne stacje
                  robocze
                </li>
                <li>oprogramowanie do projektowania CAD - CATIA V5</li>
                <li>
                  oprogramowanie do obliczeń numerycznych MES - NEi Nastran
                </li>
              </ul>
              <h3 className="margin-top">
                Każdy projekt realizujemy wg podstawowych etapów
              </h3>
              <ul>
                <li>badanie rynku</li>
                <li>
                  koncepcja i wybór optymalnego rozwiązania w uzgodnieniu z
                  klientem
                </li>
                <li>opracowanie projektu technicznego urządzenia</li>
                <li>komputerowa wizualizacja projektu</li>
                <li>
                  w razie potrzeby wykonanie analiz wytrzymałościowych z
                  wykorzystaniem komputerowych narzędzi do obliczeń i symulacji
                </li>
                <li>
                  opracowanie pełnej dokumentacji konstrukcyjnej i
                  technologicznej do wykonania urządzenia
                </li>
                <li>
                  w razie potrzeby opracowanie dokumentacji przyrządów i pomocy
                  warsztatowych
                </li>
              </ul>
              <p>Służymy Państwu fachową poradą i pomocą techniczną. </p>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
}

export default About;
