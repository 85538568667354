// --------------------------------------- Project List
export const ProjectList = [
  {
    img: require("../assets/folder1.png").default,
    title: "Obliczenia wytrzymałościowe",
    description: "Numeryczne obliczenia inżynierskie MES",
    photos: [
      {
        src: require("../assets/WWW_1/picturemessage_xcxr40qm.vkt.png").default,
        width: 6,
        height: 3,
      },
      {
        src: require("../assets/WWW_1/T6.jpg").default,
        width: 6,
        height: 3,
      },
      {
        src: require("../assets/WWW_2/www-17.jpg").default,
        width: 4,
        height: 3,
      },
      {
        src: require("../assets/WWW_1/6ND6Ee.png").default,
        width: 4,
        height: 3,
      },
      {
        src: require("../assets/WWW_1/gZWgMd.png").default,
        width: 4,
        height: 3,
      },
    ],
  },
  {
    img: require("../assets/folder2.png").default,
    title: "Przyrządy montażowe i urządzenia transportu wewnętrzengo",
    description:
      "Urządzenia dla przemysłu lotniczego i automotive, urządzenia dźwigowe",
    photos: [
      {
        src: require("../assets/WWW_3/www-26.jpg").default,
        width: 3,
        height: 4,
      },
      {
        src: require("../assets/WWW_3/www-27.jpg").default,
        width: 4,
        height: 3,
      },
      {
        src: require("../assets/WWW_3/www-29.jpg").default,
        width: 3,
        height: 4,
      },
      {
        src: require("../assets/WWW_3/www-30.jpg").default,
        width: 3,
        height: 4,
      },
      {
        src: require("../assets/WWW_1/www-01.jpg").default,
        width: 3,
        height: 3,
      },
      {
        src: require("../assets/WWW_1/www-09.jpg").default,
        width: 4,
        height: 3,
      },
      {
        src: require("../assets/WWW_2/www-15.jpg").default,
        width: 6,
        height: 3,
      },
      {
        src: require("../assets/WWW_2/www-18.jpg").default,
        width: 6,
        height: 3,
      },
      {
        src: require("../assets/WWW_2/www-22.jpg").default,
        width: 3,
        height: 4,
      },
      {
        src: require("../assets/WWW_2/www-23.jpg").default,
        width: 3,
        height: 4,
      },
      {
        src: require("../assets/WWW_2/www-24.jpg").default,
        width: 6,
        height: 3,
      },
    ],
  },
  {
    img: require("../assets/WWW_4/iKuz7z.png").default,
    title: "Linie technologiczne",
    description: "",
    photos: [
      {
        src: require("../assets/WWW_4/iKuz7z.png").default,
        width: 6,
        height: 3,
      },
      {
        src: require("../assets/WWW_4/coqkus.png").default,
        width: 6,
        height: 3,
      },
    ],
  },
  {
    img: require("../assets/folder3.png").default,
    title: "Dokumentacja techniczna",
    description: "",
    photos: [
      {
        src: require("../assets/WWW_2/www-12.jpg").default,
        width: 6,
        height: 3,
      },
      {
        src: require("../assets/WWW_2/www-13.jpg").default,
        width: 6,
        height: 3,
      },
    ],
  },
  {
    img: require("../assets/samoloty_historyczne_logo_300.3487648d.jpg")
      .default,
    title: "Inne",
    description: (
      <div>
        <p className="justifyContent">
          W ramach współpracy z fundacją Polskie Samoloty Historyczne odtwarzamy
          dokumentację techniczną przedwojennego polskiego myśliwca PZL P11c.
          Dokumentacja ma posłużyć do budowy latającej repliki samolotu.
        </p>
        <p>
          Więcej informacji na&nbsp;
          <a
            href="https://samolotyhistoryczne.org"
            target="_blank"
            rel="noreferrer"
            className="normal"
          >
            https://samolotyhistoryczne.org
          </a>
        </p>
      </div>
    ),
    photos: [
      {
        src: require("../assets/3ATQUd.png").default,
        width: 5,
        height: 3,
      },
    ],
  },
];
