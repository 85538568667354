import React from "react";
import styled from "styled-components";

const FooterSection = styled.div`
  background-image: url(${(props) => props.backgroundImg});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 250px;
  position: relative;

  span {
    position: absolute;
    bottom: 4rem;
    color: #fff;

    a {
      text-decoration: underline;
    }
  }
`;
function Footer() {
  const backgroundImg = require("../../assets/tlo_koniec.png").default;

  return <FooterSection id="footer" backgroundImg={backgroundImg} />;
}

export default Footer;
