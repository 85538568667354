import styled from "styled-components";

export const ContactWrapper = styled.div`
  margin: 5rem 0;
`;

export const StyledDiv = styled.div`
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;

  @media (min-width: 576px) {
    font-size: 1.5rem;
  }
  @media (min-width: 992px) {
    margin-bottom: 0;
    font-size: 1.5rem;
  }
`;

export const Email = styled.div`
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;

  @media (min-width: 576px) {
    font-size: 1.5rem;
  }
  @media (min-width: 992px) {
    font-size: 3rem;
  }
`;
